<template>
<div>
  <loading-overlay v-if="!buildingsData && !buildingsError" />
  <div class="flex flex-col m-8">
    <div class="mb-6 p-4 px-5 flex flex-col md:flex-row bg-gray-50 rounded-md text-gray-800">
      <div class="flex flex-col flex-1">
          <div class="flex items-baseline">
            <img src="@/assets/svg/current-calendar-icon.svg" alt="" class="h-4 w-auto mr-2 mt-1">
            <h3 class="text-lg font-bold">Current</h3>
          </div>
          <div class="py-6 space-x-2 flex flex-wrap">
            <dashboard-rollup-risk-badge
              v-for="(count, risk) in rollupRiskStateBuildingCount"
              :key="risk"
              @set-query-string-to="buildingQuery = $event"
              :riskType="risk"
              :count="count" />
          </div>
      </div>
    </div>

    <div class="mb-4 flex justify-between items-center">
      <div class="flex flex-wrap flex-grow items-center">
        <FilterInput
          v-model="buildingQuery"
          class="w-2/3 max-w-sm mr-4"
          placeholder="Filter by name, address, city, risk, etc."
        />
        <p class="text-sm text-gray-600">{{ numberOfBuildingsText }}</p>
      </div>
      <button
      class="inline-flex px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 print:hidden"
      @click="showAddBuildingModal = true"
      >+ Add Building</button>
    </div>

    <div class="mb-7 flex justify-between items-center">
      <dashboard-map
        :buildings="filteredBuildings"
        @go-to-detail="goToSpace"
      />
    </div>

    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex flex-row">
                  Building
                  <loading-spinner v-show="(buildingsData && isValidating)" class="ml-2 h-4 w-4 filter grayscale"/>
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  City
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Region
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Group
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Risk rating
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200" :key="buildingQuery">
              <tr v-for="building in filteredBuildings" :key="building.key" :class="rowClass(building)">
                <td>
                  <router-link :to="createRoute(building)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ building.name ?? building.shortAddress }}
                      <span v-if="building.name" class="mt-1 text-xs text-gray-600 block">{{ building.shortAddress }}</span>
                    </div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="createRoute(building)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ building.city }}
                    </div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="createRoute(building)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ building.region }}
                    </div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="createRoute(building)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ building.group }}
                    </div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="createRoute(building)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ building.lastUpdated }}
                    </div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="createRoute(building)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap">
                      <dashboard-risk-badge :risk="building.risk" :badgeStyle="'medium'" />
                    </div>
                  </router-link>
                </td>
                <td>
                  <Menu as="div" class="mr-2 relative">
                    <MenuButton class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 print:hidden" id="menu-button" aria-expanded="true" aria-haspopup="true">
                      <span class="sr-only">Open options</span>
                      <DotsVerticalIcon class="h-4 w-4" />
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                        <div class="py-1" role="none">
                          <MenuItem as="button" @click="editBuilding(building)" class='disabled:opacity-50 text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-0">Edit building</MenuItem>
                          <MenuItem as="button" @click="showConfirmDeleteBuilding(building)" class='disabled:opacity-50 text-red-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-1">Delete building</MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <create-building-form
      :showModal="showAddBuildingModal"
      :building="buildingToEdit"
      @close="closeModal"
    />

    <title-message-modal :showModal="buildingToDelete != null">
      <template v-slot:header>
        <h1 class="text-lg font-medium text-gray-900">Delete {{ buildingToDelete?.name ? buildingToDelete?.name : 'building' }}</h1>
      </template>

      <template v-slot:main>
        <p class="text-sm font-normal text-gray-500">Are you sure you want to delete this building? All of your data will be permanently deleted. This cannot be undone.</p>
      </template>

      <template v-slot:footer>
        <button
          class="inline-flex justify-center sm:items-center px-4 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          @click="cancelDelete()">
          Cancel
        </button>
        <button v-if="isDeletingBuilding" class="inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
            <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          </svg>
          Deleting...
        </button>
        <button
          v-else
          @click="confirmDeleteBuilding()"
          class="inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          Delete building
        </button>
      </template>
    </title-message-modal>
  </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { DotsVerticalIcon } from '@heroicons/vue/solid';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';

import DashboardRiskBadge from './DashboardRiskBadge.vue';
import DashboardRollupRiskBadge from './DashboardRollupRiskBadge.vue';
import CreateBuildingForm from './CreateBuildingForm.vue';
import FilterInput from '../forms/inputs/FilterInput.vue';
import TitleMessageModal from '@/components/modals/TitleMessageModal.vue';
import { RiskState } from '@/models/dashboard';
import DashboardMap from './map/DashboardMap.vue';
import LoadingOverlay from '../overlays/LoadingOverlay.vue';
import LoadingSpinner from '../spinners/LoadingSpinner.vue';
import { DashboardBuilding } from '@/models/dashboard';

import buildingApiService from '@/network/services/building';

export default {
  name: 'DashboardBuildingSummary',
  pageTitle: 'Buildings',
  components: {
    DashboardRiskBadge,
    DashboardRollupRiskBadge,
    CreateBuildingForm,
    FilterInput,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    TitleMessageModal,
    DashboardMap,
    LoadingOverlay,
    LoadingSpinner,
  },
  data() {
    return {
      buildingQuery: '',
    };
  },
  computed: {
    buildings() {
      return (this.buildingsData ?? [])
        .map(building => new DashboardBuilding(building));
    },
    filteredBuildings() {
      return this.buildings.filter(b => {
        // Construct a composite building object using subset of DashboardBuilding and ParticleOneBuilding properties:
        const { name, city, region, group, risk } = b; // DashboardBuilding
        const { street_address, country } = b.building; // response
        const subsetBuilding = { name, city, region, group, risk: risk.title, street_address, country };
        // Check whether any values contain the query (lowercase comparison)
        // An empty query string not filter any results
        return Object.values(subsetBuilding)
          .map(value => String(value).toLowerCase())
          .some(value => value.includes(this.buildingQuery.toLowerCase()));
      });
    },
    numberOfBuildingsText() {
      return `${this.filteredBuildings.length} ${this.filteredBuildings.length === 1 ? 'result' : 'results'}`;
    },
    rollupRiskStateBuildingCount() {
      // Initialize counts by risk type => {Pending:0, Caution:0, Prepared:0}
      const initialRiskCounts = Object.fromEntries(Object.values(RiskState).map(riskState => ([riskState, 0])));
      // Count risk types for all buildings and return {Pending:X, Caution:Y, Prepared:Z}
      return this.buildings.reduce((riskCount, building) => {
        riskCount[building.risk.title]++;
        return riskCount;
      }, initialRiskCounts);
    }
  },
  setup() {
    const showAddBuildingModal = ref(false);
    const buildingToEdit = ref(null);
    const buildingToDelete = ref(null);
    const isDeletingBuilding = ref(false);

    const { data: buildingsData, error: buildingsError, isValidating, mutate: reloadBuildings } = buildingApiService.getAllBuildings();

    return {
      buildingsData,
      isValidating,
      reloadBuildings,
      buildingsError,
      showAddBuildingModal,
      buildingToEdit,
      buildingToDelete,
      isDeletingBuilding
    };
  },
  methods: {
    goToSpace(building) {
      this.$router.push({ name: 'DashboardSpaceSummary', params: { buildingId: building.id } });
    },
    createRoute(building) {
      return { name: 'DashboardSpaceSummary', params: { buildingId: building.id } };
    },
    rowClass(building) {
      return building.isNewlyCreated ? 'new hover:bg-gray-100 cursor-pointer' : 'hover:bg-gray-100 cursor-pointer';
    },
    editBuilding(building) {
      this.buildingToEdit = building;
      this.showAddBuildingModal = true;
    },
    closeModal() {
      this.showAddBuildingModal = false;
      this.buildingToEdit = null;
      this.reloadBuildings();
    },
    async showConfirmDeleteBuilding(building) {
      this.buildingToDelete = building;
    },
    async confirmDeleteBuilding() {
      try {
        this.isDeletingBuilding = true;
        await buildingApiService.deleteBuilding(this.buildingToDelete.id);
      } finally {
        await this.reloadBuildings();
        this.isDeletingBuilding = false;
        this.buildingToDelete = null;
      }
    },
    async cancelDelete() {
      this.buildingToDelete = null;
    },
  }
};
</script>

<style scoped>
.list-enter-active, .list-leave-active {
  transition: all 1s ease 5s;
}

.new.list-enter-from, .new.list-leave-to {
  transition: all 1s ease 5s;
  background: #FFFBEB;
  animation: background-fade 1s ease 5s;
  -webkit-animation: background-fade 1s ease 5s;
  -moz-animation: background-fade 1s ease 5s;
}

@-webkit-keyframes background-fade {
  0% {
    background: #FFFBEB;
  }
  100% {
    background: #FFFFFF;
  }
}
@-moz-keyframes background-fade {
    0% {
    background: #FFFBEB;
  }
  100% {
    background: #FFFFFF;
  }
}
@keyframes background-fade {
  0% {
    background: #FFFBEB;
  }
  100% {
    background: #FFFFFF;
  }
}
</style>
